.layout-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.layout-body {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: auto;

  > .nav {
    min-width: fit-content;
  }

  .layout-page {
    flex: 1;
    flex-grow: 20;
    overflow: auto;
  }
}
