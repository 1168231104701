.drag-container {
  background-color: rgba(255, 255, 255, 0.8);
  margin: auto;
  display: flex;
  width: 70%;
  max-width: 600px;
  flex-direction: column;
  align-items: center;

  .drag-canvas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 173px;
    border-radius: 11px;
    border: dashed 2px #389eff;
    background-color: #f3faff;
  }
}
