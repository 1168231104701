@import '../core/layout.scss';

.document-preview-modal {
  width: 40%;
  max-width: 40%;

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .actions-preview {
    .MuiButtonBase-root {
      margin: 5px;
    }
  }
}
