@import '../global/variables.scss';

.navbar.layout-navbar {
  background-color: $blue-treezor-color;

  a {
    color: $light-color;
  }

  .current-agent-name {
    > svg {
      margin-right: 10px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-size: large;
    font-weight: bold;
  }

  button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    border-radius: 0;
  }

  button:hover {
    background-color: transparent;
    color: $blue-dark-color;
  }

  button:not(:last-child) {
    border-right: solid 0.8px $light-color;
  }
}
.dropdownItem-navbar {
  color: rgb(224, 14, 14);
  background-color: #10b4e6;
}
