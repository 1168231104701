.stepper {
  background-image: url(../../assets/images/stepper.svg);
  background-size: cover;
  width: 100vw;
  color: white !important;
  position: relative;
  height: 100vh;
  overflow: hidden;

  .stepper-language {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
  }

  .stepper-form {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 0 auto;

    .stepper-title {
      padding-bottom: 35px;
    }

    .stepper-input {
      max-height: 400px;
      overflow-y: auto;

      .MuiFormGroup-root {
        padding-left: 20px;
      }

      .response {
        display: block !important;
        width: 100%;
        color: white !important;
      }

      .showPassword {
        cursor: pointer;
      }

      ::-webkit-calendar-picker-indicator {
        filter: brightness(0) invert(1);
      }
    }

    .hidden {
      display: none;
    }
    .question {
      display: block;
      font-size: 24px;
      font-weight: 400 !important;
      font-family: 'system-ui' !important;
      margin-bottom: 0px;
    }
    .input-label {
      font-style: italic;
      font-size: 18px;
      font-family: 'system-ui' !important;
      line-height: 54px;
      font-weight: 300;
      margin-top: 0px;
    }

    .MuiFormLabel-root,
    .MuiInputBase-root {
      color: white;
    }
  }

  .button-container {
    margin-top: 35px;
    button {
      margin-right: 10px;
    }
    .previous,
    .next {
      font-size: small;
      color: white;
      text-transform: unset !important;
    }

    .previous.Mui-disabled,
    .next.Mui-disabled {
      color: #859cf1;
    }

    text-align: center;

    .no-siren-link.ok {
      color: #285eea;
      font: 'Roboto';
      size: 20px;
      align-items: center;
      border-radius: 50px;
      width: 222px;
      margin-left: 30px;
      margin-right: 30px;
    }

    .no-siren-link.submit {
      color: white;
      font: 'Roboto';
      size: 20px;
      align-items: center;
      border-radius: 50px;
      width: 222px;
      margin-left: 30px;
      margin-right: 30px;
      background-color: #1745bd;
      box-shadow: 1px 1px 1px #0d1a3c;
      font-weight: 600;
    }

    .check-image {
      width: 20px;
    }
  }

  .MuiFormControlLabel-root {
    background-color: white;
    color: black;
    .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
      text-align: center;
    }
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .MuiSelect-iconOutlined {
    color: white;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: #2376e5;
  }
}
