@import '../core/layout.scss';

@mixin box($size) {
  @include flex-column;
  flex: 1;
  padding: 6px;

  > .title {
    font-size: 17.6px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3d4f65;
    text-transform: uppercase;
    margin-left: 20px;
  }

  > .card {
    flex: $size;
    border-radius: 12px;
    box-shadow: 0 0 7px 2px rgba(98, 127, 162, 0.1);
    background-color: #ffffff;
    padding: 10px;
    margin: 10px;

    .card-label {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3d4f65;
    }

    .card-values {
      @include flex-column;
      align-items: flex-end;
      max-width: 300px;
    }

    .card-contact {
      @include flex-column;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 10px;

      .card-values {
        margin-top: 15px;
        align-items: flex-start;

        > div {
          @include flex-row;
          margin-left: 20px;

          > div {
            margin-left: 10px;
          }
        }

        .MuiFormControl-root {
          @include flex-column;
          width: 100%;
          margin-bottom: 5px;
          margin-top: 5px;
        }
      }
    }

    .card-info {
      @include flex-row;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 10px;

      input {
        width: 100%;
        max-width: 300px;
      }
    }
  }
}

.overview {
  min-width: 100%;
  @include flex-row;

  .MuiOutlinedInput-input {
    padding: 9px 12px;
  }

  .overview-left {
    @include flex-column;
    flex: 2;

    .overview-container {
      @include box(9);
    }

    .informations {
      > .card {
        @include flex-row;

        > .column-left {
          flex: 1;
        }

        > .column-right {
          flex: 1;
        }
      }
    }

    .contacts, .prospect-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .overview-container {
        justify-content: flex-start;
      }

      .overview-container:first-child {
        display: flex;

        .card {
          flex-direction: row;
          flex: 2;
        }
      }

      .overview-container:nth-child(2) {
        flex: 1;
      }
    }

    .MuiLinearProgress-root {
      height: 8px !important;
      border-radius: 10px !important;
    }

    .MuiLinearProgress-colorPrimary {
      background-color: rgba(216, 218, 233, 0.986) !important;
    }
  }

  .overview-right {
    @include flex-column;
    flex: 1;

    .overview-container {
      @include box(1);

      .notification {
        padding: 6px;
        margin-top: 10px;
        margin-bottom: 3px;
        cursor: pointer;
        padding: 15px;

        .notification-header {
          @include flex-row;

          > div:first-child {
            @include flex-row;
            color: #2370e3;
            font-style: italic;
            font-size: 0.9em;

            .MuiAvatar-root {
              width: 25px;
              height: 25px;
              font-size: small;
              margin-right: 10px;
            }
          }

          > div:nth-child(2) {
            font-size: small;
          }
        }

        .notification-content {
          margin-top: 5px;
          margin-left: 35px;
          font-size: small;

          .document-title {
            font-weight: 900;
            font-feature-settings: 'smcp';
            font-size: 1.3em;
          }
        }
      }
    }
  }
}
.actions {
  position: absolute;
  bottom: 1vw;
  right: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;

  > .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3vw;
    height: 3vw;
    font-size: 2vw;
    margin: 5px;
  }
}

.card-prospect {
  border-radius: 12px;
  box-shadow: 0 0 7px 2px rgba(98, 127, 162, 0.1);
  background-color: #ffffff;
  padding: 10px;
  margin: 10px;
  flex: 0 1;
  .flex-item {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    margin-right: 20px;
    width: 100%;
    .label {
      font-style: normal;
      font-size: 16px;
      display: contents;
      color: #6d6b6b;
      margin-bottom: 0px;
    }
    .value {
      font-style: normal;
      font-family: 'RobotoMedium';
      font-size: 16px;
      display: contents;
      color: #3e5064;
      line-height: 24px;
    }

    .valuePitch {
      height: 300px;
      overflow-y: auto;
      width: 30vw;
    }
    margin-bottom: 30px;
  }
}
.title-company{
  height: 20px;
}
.overview-container.company{
  height: 670px;
  overflow-y: auto;
  padding-top: 20px
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(177, 176, 176);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}
