@import "../core/layout.scss";

.form-container {
  @include flex-column;
  padding: 10px;

  .inputs-container {
    @include flex-column;
    padding: 10px;

    > .personal {
      @include flex-row;
      .MuiCard-root {
        width: 50%;
      }
    }

    .MuiCard-root {
      @include flex-column;
      padding: 10px;
      margin: 10px;
    }

    .MuiTextField-root {
      margin: 10px;
    }

    .subtitle {
      @include flex-row;
      justify-content: space-between;

      svg {
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    .left-container {
      @include flex-column;
      padding: 10px;
    }

    .right-container {
      @include flex-column;
      padding: 10px;
    }
  }
}
