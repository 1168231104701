.layout-sidebar-expand.nav,
.layout-sidebar.nav {
    background-color: $grey-light-color;
    height: 100%;
    min-width: fit-content;
    overflow: unset;

    button {
        background-color: transparent;
        color: $blue-treezor-color;
        font-size: 14.1px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    button:hover {
        background-color: transparent;
        color: $blue-dark-color;
    }
    
    .btn-expand{
        margin-top: auto;
        margin-bottom: 10px;
        text-align: center;
    }

}

.layout-sidebar.nav {
    align-items: center;
    button {
        font-size: 18px;
    }
}
