.admin-page {
  .input-group,
  .file-input {
    width: 33%;
    margin-top: 5px;
  }

  .separator {
    border-bottom: solid 1px black;
    width: 24%;
    transform: translateX(12%);
  }

  .onboard-multiple-btn {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.alert-onbordingprospect {
  display: flex;
  justify-content: center;
  align-items: center;
}
