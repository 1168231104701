.btn {
  border-radius: 23px;
  font-family: Raleway;
  font-size: 13.1px;
  font-weight: 600;
}

.btn-primary {
  background-image: linear-gradient(273deg, #3ba1ff 100%, #59bfff 0%);
}

.btn-secondary {
  background-color: #3d4f65;
}

label {
  font-size: 12px;
  margin-bottom: 0;
}

input {
  border-radius: 9.7px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  border: solid 0.6px #e0e0e0;
}

input::placeholder {
  font-size: 12px;
}

.form-column {
  .checkbox {
    display: inline;
  }

  h5 {
    font-size: 12px;
  }

  > .form-group {
    display: block;

    > .my-1 > h5 {
      font-size: 1.25rem;
    }

    > .container-fluid {
      column-count: 2;

      > .row {
        display: block;
      }
    }
  }
}
