@import './styles/index';

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: ' Arial,sans-serif', 'Roboto', 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local('Raleway'),
    url('./assets/fonts/raleway/Raleway-Regular.ttf') format('truetype'),
}

@font-face {
  font-family: 'RobotoRegular';
  src: local('Roboto'),
    url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype'),
}
@font-face {
  font-family: 'RobotoMedium';
  src: local('Roboto'),
    url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype'),
}
@font-face {
  font-family: 'RobotoBold';
  src: local('Roboto'),
    url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype'),
}

.alert {
  width: 100%;
  margin-bottom: 0;
}

.swal2-title {
  font-size: 1.4em;

}
.swal2-html-container{
  margin: 0.7em 1.6em 0.3em;
  font-size: 1.05em;
}

.swal2-actions {
  margin: 1.25em auto 0;
}

.swal2-styled {
  padding: 0.4em 1.1em;
}