.app-nav-item {
  padding: 10px;

  .btn {
    border-radius: 12px;
    padding: 15px;
    background-color: transparent;
    border-width: 0 !important;

    :focus {
      border: none !important;
    }
  }

  .app-nav-item-icon {
    color: lightgray;
  }

  .app-nav-item-label {
    margin-left: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3d4f65;
  }
}

.app-nav-item.selected {
  .btn {
    background-color: #e3f3ff;
  }

  .app-nav-item-icon {
    color: #389eff;
  }

  .app-nav-item-label {
    font-weight: 600;
    color: #389eff;
  }
}
