.agent-container {
  height: 100%;
  overflow: auto;
}

.MuiTab-wrapper {
  align-items: center;
  justify-content: center;
  font-size: 17px;
  flex-direction: row !important;
  svg {
    margin-right: 10px;
    font-size: 26px;
  }
}
