.profile-page {
  .MuiCardContent-root {
    .MuiGrid-root {
      > .MuiGrid-root {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}
