.pre-prospect {
  background-image: url(../../assets/images/preProspect.svg);
  background-size: cover;
  width: 100vw;
  position: relative;
  height: calc(100vh - 58px);
  display: flex;
  flex-wrap: nowrap;
  font-family: 'RobotoRegular';
  > .welcome {
    width: 34%;
    color: white !important;
    display: flex;
    flex-direction: column;
    padding-top: 13%;
    height: 100%;
    margin-left: 68px;
    padding-right: 50px;
    .message {
      font-style: normal;
      font-size: 45px;
      line-height: 60px;
      align-items: center;
      color: #ffffff;
      font-family: 'RobotoBold';
    }

    .change_status {
      margin-left: auto;
      margin-right: auto;
      display: inline-block;
      margin-top: 40px;

      .validate,
      .refuse {
        text-transform: unset !important;
        align-items: center;
        border-radius: 50px;
        padding: 5px 30px;
        font-family: 'RobotoBold';
        font-size: 15px;
      }

      .validate {
        margin-right: 20px;
        background-color: #15c1e8;
        box-shadow: 1px 1px 1px #033f4c;
        border-color: #15c1e8;
      }

      .refuse {
        background-color: #ff2e51;
        box-shadow: 1px 1px 1px #491e25;
        border-color: #ff2e51;
      }
    }

    .information {
      font-style: normal;
      font-size: 16px;
      line-height: 25px;
      align-items: center;
      margin-right: 100px;
      margin-top: 30px;
    }
  }

  > .information {
    width: 66%;
    background-color: #f7f9ff !important;
    padding: 80px;
    overflow-y: hidden;

    .title {
      > p:first-child {
        font-style: normal;
        font-family: 'RobotoBold';
        font-size: 40px;
        line-height: 55px;
        display: flex;
        align-items: center;
        color: #3e5064;
      }
      > p:nth-of-type(2) {
        font-style: normal;
        font-size: 16px;
        line-height: 25px;
        align-items: center;
        color: #979797;
        width: 450px;
      }
    }

    .content {
      margin-top: 70px;
      overflow-y: scroll;
      height: 65vh;
      > div {
        background: #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 83, 240, 0.05);
        border-radius: 8px;
        margin-top: 25px;
      }

      .flex-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: flex-start;
        padding: 40px 20px 0px 50px;
        margin-top: 0px;

        .flex-item {
          order: 0;
          flex: 0 1 auto;
          align-self: auto;
          margin-right: 20px;
          width: 30%;
          .label {
            font-style: normal;
            font-size: 16px;
            display: flex;
            color: #c4c4c4;
            margin-bottom: 0px;
          }
          .value {
            font-style: normal;
            font-family: 'RobotoMedium';
            font-size: 16px;
            display: flex;
            color: #3e5064;
            line-height: 24px;
          }

          .valuePitch {
            height: 300px;
            overflow-y: auto;
            width: 30vw;
          }
          margin-bottom: 30px;
        }
      }

      .flex-container:not(:last-child) {
        margin-bottom: 20px;
      }

      .pitch-deck {
        .value {
          height: 100%;
          width: 100%;
          border: '1px solid #3E5064';
        }
      }

      .rpv-core__text-layer {
        display: none;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(177, 176, 176);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}
