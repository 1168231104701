.documents-page {
  max-width: 100% !important;
  height: 100%;

  .documents-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin: 10px;

    .btn {
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  .step-card {
    margin-bottom: 30px;
    border-radius: 12px;
    box-shadow: 0 0 13px 5px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;

    .card-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: x-large;
      background-color: #ffffff;
      border-bottom: none;
      padding-top: 20px;
      padding-top: 0px;
      padding-bottom: 0px;
      font-variant-caps: all-small-caps;

      .step-title {
        min-width: 35%;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #389eff;
      }

      .btn {
        background-color: transparent;
        border-color: transparent;
        color: #3d4f65;
        font-size: 20px;
      }

      .step-label {
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-right: 18px;
      }

      .step-progress {
        display: inline-block;
        width: 200px;
        height: 10px;

        .progress {
          border: solid 0.2px #389eff;
          border-radius: 100px;

          .progress-bar {
            background-color: #389eff;
          }
        }
      }

      .step-overview {
        display: flex;
        flex-direction: row;

        .card {
          width: 128px;
          height: 40px;
          border-radius: 5.8px;
          border: solid 0.8px #3d4f653d;
          margin: 9px;
          align-items: center;
          justify-content: center;

          div {
            font-size: small;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #3d4f65;

            span {
              font-weight: 600;
            }
          }
        }
      }
    }

    .card-body {
      .document-table-header {
        display: flex;
        flex-wrap: wrap;
        background-color: #f3faff;
        padding: 10px;
        text-align: center;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3d4f65;
        font-size: large;
        font-variant-caps: small-caps;

        div:first-child {
          text-align: left;
        }
      }

      .document-table-body {
        .document-row:not(:last-child) {
          border-bottom: solid 1px #97979744;
          padding-bottom: 10px;
        }

        .document-validation-container {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          div {
            text-align: center;
            font-size: small;

            .MuiButtonBase-root {
              margin-left: 2px;
              margin-right: 2px;
            }
          }
        }

        .document-values {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          div:not(:first-child) {
            text-align: center;
            font-size: small;
          }

          div:first-child {
            font-weight: 600;
          }

          .document-name {
            padding-left: 10px;

            .badge {
              border-radius: 50%;
            }
          }

          .document-status {
            display: flex;
            flex-direction: column;

            span:first-child {
              font-size: medium;
              font-weight: 500;
            }

            .document-validation {
              margin-top: 4px;
              .btn {
                margin-left: 4px;
                margin-right: 4px;
              }
            }
          }

          .status-color {
            display: inline-block;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            margin-right: 18px;
          }

          div {
            color: #3d4f65;
            box-sizing: border-box;
            padding: 0.8em 1.2em;
            list-style: none;
            background: fade(slategrey, 20%);
          }

          .document-actions {
            padding-right: 0;
            padding-left: 0;

            .user-actions {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding-right: 0;
              padding-left: 0;

              svg {
                cursor: pointer;
                font-size: large;
              }

              svg.disabled {
                color: #9797974f;
                pointer-events: none;
                cursor: default;
              }
            }

            .admin-actions {
              color: #3d4f65;
            }
          }
        }

        .comments-container {
          padding-left: 30px;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          align-items: center;
          color: #3d4f65;

          .comments {
            display: flex;
            flex-direction: column;
            align-items: center;

            .discussion {
              display: flex;
              border-radius: 12px;
              box-shadow: 0 0 13px 5px rgba(0, 0, 0, 0.05);
              background-color: #ffffff;
              max-width: 600px;
              width: 100%;
              max-height: 400px;
              overflow: scroll;
              flex-direction: column;
              padding: 10px;

              .comment {
                display: inline-flex;
                flex-direction: column;
                flex-wrap: nowrap;
                margin: 5px;
                max-width: 90%;
                padding: 10px;
                border-radius: 10px;
                color: white;
                width: fit-content;

                &::after {
                  content: '';
                  position: absolute;
                  top: 75%;
                  width: 0;
                  height: 0;
                  border: 10px solid transparent;
                }

                &.right-comment {
                  align-self: flex-end;
                  background-color: #389eff;
                  color: white;

                  &::after {
                    border-left-color: #389eff;
                    right: 0;
                    border-right: 0;
                    border-bottom: 0;
                    margin-top: -5px;
                    margin-right: -10px;
                  }
                }

                &.left-comment {
                  align-self: flex-start;
                  background-color: #f1f0f0;
                  color: black;

                  &::after {
                    border-right-color: #f1f0f0;
                    left: 0;
                    border-left: 0;
                    border-bottom: 0;
                    margin-top: -4px;
                    margin-left: -10px;
                  }
                }
              }
            }
          }

          .comment-form {
            max-width: 600px;
            width: 100%;
            display: flex;
            margin-top: 15px;

            .MuiTextField-root {
              width: 90%;
            }

            .MuiButton-root {
              width: 10%;
            }
          }
        }
      }
    }
  }
}
