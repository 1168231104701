.get-started-instructions-siren {
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 0.9em;
    align-self: flex-start;
}

.get-started-form-siren {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    .get-started-inputs-siren {
        margin-bottom: 1%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .siren-inputs {
        display: flex;
        flex-direction: column;

        .no-siren-link {
            font-size: 0.7em;
            justify-content: flex-end;
            align-self: flex-end;
        }
    }
}