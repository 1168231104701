.get-started-instructions-passwordcreation {
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 0.9em;
    align-self: flex-start;
}

.get-started-form-passwordcreation {
    width: 100%;
    display: flex;
    flex-direction: column;
    .get-started-inputs-passwordcreation {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
