.get-started-instructions-prospect {
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 0.9em;
    align-self: flex-start;
}

.get-started-form-prospect {
    width: 100%;
    display: flex;
    flex-direction: column;

    .get-started-inputs-prospect {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .get-started-legal-representant-prospect {
            // margin-left: 5%;

            // .input-formgroup-details {
            //     margin-bottom: 5px;

            //     .input-formgroup-details-firstname {
            //         margin-bottom: 5px;
            //     }
            // }
        }

        .get-started-legal-company-details {
            padding-right: 5%;
        }
    }
}