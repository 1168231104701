.login-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: #fafbfc;

    .form-container {
        flex: 3;
        display: flex;
        width: 40%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .form-content {
            width: 50%;
        }

        .login-title {
            font-size: 22.7px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3d4f65;
            margin-bottom: 10%;
            border-width: solid;

            .login-title-bold {
                font-weight: bold;
            }
        }

        .line {
            width: 59px;
            height: 1px;
            border-bottom: solid 1.9px #3d4f65;
        }

        .line-login {
            width: 70%;
            height: 1px;
            border-bottom: solid 1.9px #3d4f65;
            margin-top: 6%;
            margin-left: auto;
            margin-right: auto;
        }


        .login-form {
            margin-top: 15%;

            .login-inputs {
                margin-bottom: 20%;
            }
            .login-inputs-error {
                margin-bottom: 23%;
            }

            .login-buttons {
                width: 80%;
                margin: 0 auto;
            }
        }

    }

    .image-container {
        flex: 4;
        background-color: #3ba1ff;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        .macbook-image {
            width: 80%;
        }
    }
}